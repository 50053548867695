import SearchForm from "components/SearchForm";
import { Container } from "react-bootstrap";
import { Outlet, useLocation } from "react-router-dom";
import Footer from "./Footer";
import Header from "./Header";
import "./index.css";

export default function DefaultPage() {
	const { pathname } = useLocation();

	return (
		<>
			<Header />

			{pathname === "/" && <SearchForm />}

			<Container className="main-container">
				<Outlet />
			</Container>

			<Footer />
		</>
	);
}
