import CardProduct from "components/CardProduct";
import { Col, Row } from "react-bootstrap";
import products from "data/products";
import locals from "data/locals";
import CardLocal from "components/CardLocals";
import "./index.css";
import VideoMarketing from "components/VideoMarketing";

export default function HomePage() {
	return (
		<>
			<section>
				<VideoMarketing />
			</section>

			<section>
				<h1 className="page-title">Mais vendidos</h1>
				<Row lg={4} md={3} xs={2} className="g-4">
					{products.map((el, index) => (
						<Col key={index}>
							<CardProduct {...el} />
						</Col>
					))}
				</Row>
			</section>

			<section>
				<h1 className="page-title">Parceiros</h1>
				<Row md={3} sm={2} xs={1} className="g-4">
					{locals.map((el, index) => (
						<Col key={index}>
							<CardLocal {...el} />
						</Col>
					))}
				</Row>
			</section>
		</>
	);
}
