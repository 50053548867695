import { Card } from "react-bootstrap";
import { RiMapPin2Line } from "react-icons/ri";
import "./index.css";

interface ICardLocal {
	name: string;
	city: string;
	imageSrc: string;
}

export default function CardLocal({ name, city, imageSrc }: ICardLocal) {
	return (
		<Card className="card-local p-5">
			<Card.Img src={imageSrc} className="card-local-image" />
			<Card.Title className="card-local-name">{name}</Card.Title>
			<Card.Text className="card-local-city">
				<RiMapPin2Line /> {city}
			</Card.Text>
		</Card>
	);
}
