import { BrowserRouter, Routes, Route } from "react-router-dom";
import Account from "pages/Account";
import Login from "pages/Account/Login";
import Register from "pages/Account/Register";
import DefaultPage from "./pages/Default";
import HomePage from "./pages/Home";
import Cart from "pages/Cart";
import CartContextProvider from "contexts/cart";
import Checkout from "pages/Checkout";
import SearchPage from "pages/Search";

export default function AppRoutes() {
	return (
		<CartContextProvider>
			<BrowserRouter>
				<Routes>
					<Route path="/" element={<DefaultPage />}>
						<Route index element={<HomePage />} />
						<Route path="cart" element={<Cart />} />
						<Route path="checkout" element={<Checkout />} />
						<Route path="search/" element={<SearchPage />} />
					</Route>
					<Route path="auth" element={<Account />}>
						<Route path="login" element={<Login />} />
						<Route path="register" element={<Register />} />
					</Route>
				</Routes>
			</BrowserRouter>
		</CartContextProvider>
	);
}
