import Container from "react-bootstrap/Container";
import Navbar from "react-bootstrap/Navbar";
import logo from "../../../assets/smark.png";
import { AiOutlineShoppingCart } from "react-icons/ai";
import { BiUserCircle } from "react-icons/bi";
import { Badge } from "react-bootstrap";
import { Link } from "react-router-dom";
import useCart from "hooks/cart";
import "./index.css";

export default function Header() {
	const cart = useCart();

	return (
		<Navbar className="navbar-top-menu">
			<Container>
				<Navbar.Brand>
					<Link to="/" className="d-flex align-items-center g-1">
						<img src={logo} width="55" height="55" className="d-inline-block align-top" alt="Smark logo" />
						&nbsp;Smark
					</Link>
				</Navbar.Brand>

				<div>
					<Link to="/cart" className="btn">
						<AiOutlineShoppingCart size={24} color="#121212" />
						<Badge bg="danger">{cart.getQuantityOfProducts()}</Badge>
					</Link>
					<Link to="/auth/login" className="btn">
						<BiUserCircle size={24} color="#121212" />
					</Link>
				</div>
			</Container>
		</Navbar>
	);
}
