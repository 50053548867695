import Footer from "pages/Default/Footer";
import Header from "pages/Default/Header";
import { Col, Container, Row } from "react-bootstrap";
import { Outlet, useLocation } from "react-router-dom";
import login from "assets/login.svg";
import register from "assets/register.svg";
import "./index.css";

export default function Account() {
	const { pathname } = useLocation();
	let imgUrl = pathname === "/auth/login" ? login : register;

	return (
		<>
			<Header />
			<Container className="account-container">
				<Row>
					<Col sm={6} xs={12} className="d-flex align-items-center mb-5 mb-sm-0">
						<img src={imgUrl} alt="Logo Smark" className="account-image" />
					</Col>
					<Col>
						<Outlet />
					</Col>
				</Row>
			</Container>
			<Footer />
		</>
	);
}
