import { useState } from "react";
import { Form, Button, Container } from "react-bootstrap";
import { HiMicrophone, HiOutlineMicrophone } from "react-icons/hi";
import { BiSearch } from "react-icons/bi";
import SpeechRecognition, { useSpeechRecognition } from "react-speech-recognition";
import "./index.css";
import { useNavigate } from "react-router-dom";
import slugify from "common/slugify";

export default function SearchForm() {
	const { transcript, listening, resetTranscript } = useSpeechRecognition();
	const [query, setQuery] = useState(transcript);
	const navigator = useNavigate();

	function handleSubmit(event: React.FormEvent) {
		event.preventDefault();
		navigator("/search/", {
			preventScrollReset: true,
			state: {
				query: slugify(transcript || query),
			},
		});
		setQuery("");
		resetTranscript();
	}

	return (
		<div className="form-search-products-container" onSubmit={handleSubmit}>
			<Container>
				<Form className="form-search-products">
					<Form.Control
						size="lg"
						placeholder="Buscar produtos"
						value={transcript || query}
						required
						onChange={(e) => {
							resetTranscript();
							setQuery(e.target.value);
						}}
					/>
					<Button
						className={`m-auto ${listening && "recording"}`}
						onClick={() => {
							SpeechRecognition.startListening();
						}}
					>
						{listening ? (
							<HiMicrophone size={28} color="#f43265" />
						) : (
							<HiOutlineMicrophone size={28} color="#484848" />
						)}
					</Button>
					<Button type="submit" className="m-auto">
						<BiSearch size={26} color="#484848" />
					</Button>
				</Form>
			</Container>
		</div>
	);
}
