import Swal from "sweetalert2";

export default function useAlert() {
	const Toast = Swal.mixin({
		toast: true,
		position: "top-end",
		showConfirmButton: false,
		timer: 3000,
		timerProgressBar: true,
		didOpen: (toast) => {
			toast.addEventListener("mouseenter", Swal.stopTimer);
			toast.addEventListener("mouseleave", Swal.resumeTimer);
		},
	});

	function success(message: string) {
		Toast.fire({
			icon: "success",
			title: message,
		});
	}
	function error(message: string) {
		Toast.fire({
			icon: "error",
			title: message,
		});
	}

	return {
		success,
		error,
	};
}
