import { createContext, useEffect, useState } from "react";
import ICart from "types/cart";

export const CartContext = createContext<ICart>({} as ICart);

export default function CartContextProvider({ children }: { children: any }) {
	const [cart, setCart] = useState<ICart>({} as ICart);

	useEffect(() => {
		let storage = localStorage.getItem("cart");

		if (!!storage) {
			setCart({ ...cart, products: JSON.parse(storage) });
		} else {
			localStorage.setItem("cart", JSON.stringify([]));
			setCart({ ...cart, products: [] });
		}
	}, []);

	return <CartContext.Provider value={{ products: cart.products, setCart }}>{children}</CartContext.Provider>;
}
