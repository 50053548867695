import cereais from "assets/locals/cereais.jpg";
import estrela from "assets/locals/estrela.png";
import smart from "assets/locals/smart.jpg";

const locals = [
	{
		id: 1,
		code: "local1",
		name: "Supermercado Casa dos Cereais",
		city: "Quatis",
		imageSrc: cereais,
	},
	{
		id: 2,
		code: "local2",
		name: "Supermercado Estrela",
		city: "Quatis",
		imageSrc: estrela,
	},
	{
		id: 3,
		code: "local3",
		name: "Supermercado Smart",
		city: "Quatis",
		imageSrc: smart,
	},
];

export default locals;
