import { IPrices } from "types/product";
import money from "./money";

export default function getPrices(prices: IPrices[]): string {
	let maxPrice = prices[0].price;
	let minPrice = prices[0].price;

	for (let local of prices) {
		if (local.price > maxPrice) {
			maxPrice = local.price;
		}
		if (local.price < minPrice) {
			minPrice = local.price;
		}
	}

	return `${money(minPrice)} ~ ${money(maxPrice)}`;
}
