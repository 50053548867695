import { CartContext } from "contexts/cart";
import { useContext } from "react";
import IProduct from "types/product";
import IProducts from "types/products";

export default function useCart() {
	const cartContext = useContext(CartContext);

	function setCart(products: IProducts[]) {
		cartContext.setCart({ ...cartContext, products: products });
		localStorage.setItem("cart", JSON.stringify(products));
	}

	function getQuantityOfProducts() {
		return cartContext.products?.length || 0;
	}

	function getQuantityOfProductsById(id: number) {
		return cartContext.products.find((el) => el.product.id === id)?.quantity;
	}

	function removeProduct(id: number) {
		const products = cartContext.products.filter((el) => el.product.id !== id);
		setCart(products);
	}

	function setProductQuantity(id: number, quantity: number) {
		setCart(
			cartContext.products.map((el) => {
				if (el.product.id === id) {
					el.quantity = quantity;
				}
				return el;
			})
		);
	}

	function addProduct(product: IProduct) {
		const products = [...cartContext.products];
		let newProduct = true;

		for (let el of products) {
			if (el.product.id === product.id) {
				el.quantity += 1;
				newProduct = false;
				break;
			}
		}

		if (newProduct) products.push({ product, quantity: 1 });
		setCart(products);
	}

	return {
		products: cartContext.products,
		addProduct,
		setProductQuantity,
		getQuantityOfProducts,
		getQuantityOfProductsById,
		removeProduct,
	};
}
