import { Link } from "react-router-dom";
import { Button, Form } from "react-bootstrap";

export default function FormLogin() {
	return (
		<Form className="d-flex flex-column justify-content-center h-100">
			<h1 className="page-title">Login</h1>

			<Form.Group className="form-group">
				<Form.Label>E-mail</Form.Label>
				<Form.Control size="lg" placeholder="E-mail" type="email" required />
			</Form.Group>

			<Form.Group className="form-group">
				<Form.Label>Senha</Form.Label>
				<Form.Control size="lg" placeholder="Senha" type="password" required />
			</Form.Group>

			<Form.Group className="form-group">
				<Button size="lg" type="submit" className="w-100" id="account-button">
					Login
				</Button>

				<span className="d-block mt-3 text-center">
					Ainda não tem conta? <Link to="/auth/register">Cadastre-se agora</Link>
				</span>
			</Form.Group>
		</Form>
	);
}
