import { Col, Container, Nav, Navbar, Row } from "react-bootstrap";
import { BsBuilding, BsLinkedin, BsInstagram, BsWhatsapp, BsFacebook, BsFillTelephoneFill, BsEnvelope } from "react-icons/bs";
import { MdLocationOn } from "react-icons/md";
import { Link } from "react-router-dom";
import "./index.css";

export default function Footer() {
	const fullYear = new Date().getFullYear();
	const botaoProps = { size: 28, color: "#f6f6f6" };

	return (
		<footer>
			<Container>
				<Row sm={2} xs={1}>
					<Col className="sobre">
						<h5>Smark</h5>
						<p>
							Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vestibulum at quam dignissim, ultrices tellus eget, congue
							turpis. Ut maximus posuere suscipit.
						</p>
						<Nav className="d-block">
							<Nav.Item>
								<BsFillTelephoneFill /> +55 24 3300-3599
							</Nav.Item>
							<br />
							<Nav.Item>
								<BsWhatsapp /> +55 24 99977-6655
							</Nav.Item>
							<br />
							<Nav.Item>
								<BsEnvelope /> contato@smark.com.br
							</Nav.Item>
							<br />
							<Nav.Item>
								<MdLocationOn /> Rua das Flores, 190 - Centro - Rio das Pedras/RJ
							</Nav.Item>
							<br />
							<Nav.Item>
								<BsBuilding /> 99.999.999/0001-99
							</Nav.Item>
						</Nav>
					</Col>

					<Col className="acesso-rapido">
						<h5>Acesso rápido</h5>
						<Nav>
							<Nav.Item>
								<Link to="#">Minha conta</Link>
							</Nav.Item>
							<Nav.Item>
								<Link to="#">Meus pedidos</Link>
							</Nav.Item>
							<Nav.Item>
								<Link to="#">Quem somoms</Link>
							</Nav.Item>
							<Nav.Item>
								<Link to="#">Como funciona</Link>
							</Nav.Item>
							<Nav.Item>
								<Link to="#">Termos de uso</Link>
							</Nav.Item>
							<Nav.Item>
								<Link to="#">Políticas de privacidade</Link>
							</Nav.Item>
							<Nav.Item>
								<Link to="#">Minha conta</Link>
							</Nav.Item>
							<Nav.Item>
								<Link to="#">Meus pedidos</Link>
							</Nav.Item>
							<Nav.Item>
								<Link to="#">Quem somoms</Link>
							</Nav.Item>
							<Nav.Item>
								<Link to="#">Como funciona</Link>
							</Nav.Item>
							<Nav.Item>
								<Link to="#">Termos de uso</Link>
							</Nav.Item>
							<Nav.Item>
								<Link to="#">Políticas de privacidade</Link>
							</Nav.Item>
						</Nav>
					</Col>
				</Row>

				<Nav className="redes-sociais">
					<Nav.Item>
						<Link to="#">
							<BsFacebook {...botaoProps} />
						</Link>
					</Nav.Item>
					<Nav.Item>
						<Link to="#">
							<BsInstagram {...botaoProps} />
						</Link>
					</Nav.Item>
					<Nav.Item>
						<Link to="#">
							<BsWhatsapp {...botaoProps} />
						</Link>
					</Nav.Item>
					<Nav.Item>
						<Link to="#">
							<BsLinkedin {...botaoProps} />
						</Link>
					</Nav.Item>
				</Nav>
			</Container>
			<p className="copyright">Smark &copy; {fullYear}</p>
		</footer>
	);
}
