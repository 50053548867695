import money from "common/money";
import useCart from "hooks/cart";
import { useCallback } from "react";
import { ListGroup, Row, Col, Button } from "react-bootstrap";
import { RiMapPin2Line } from "react-icons/ri";
import locals from "../../data/locals";

export default function Checkout() {
	const { products } = useCart();

	const getTotalByLocal = useCallback(
		(local: string): string => {
			let total = 0;

			for (let product of products) {
				const priceLocal = product.product.prices.find((el) => el.local === local);
				if (priceLocal) total += priceLocal.price * product.quantity;
			}

			return money(total);
		},
		[products]
	);

	return (
		<>
			<h1 className="page-title">Checkout</h1>

			<section>
				<h2 className="page-subtitle">Melhores preços na sua região</h2>
				<ListGroup>
					{locals.map((el) => (
						<ListGroup.Item key={el.id}>
							<Row>
								<Col md={2} sm={6} xs={6}>
									<img src={el.imageSrc} alt={el.name} className="img-fluid" />
								</Col>
								<Col md={10} sm={6} xs={6} className="py-3 d-flex flex-column justify-content-between">
									<h5>{el.name}</h5>
									<p className="text-muted d-flex align-items-center g-1">
										<RiMapPin2Line />
										{el.city}
									</p>
									<h2>{getTotalByLocal(el.code)}</h2>
									<Button variant="danger" className="m-xs-auto ms-md-auto">
										Comprar aqui
									</Button>
								</Col>
							</Row>
						</ListGroup.Item>
					))}
				</ListGroup>
			</section>
		</>
	);
}
