import product1 from "assets/products/product1.png";
import product2 from "assets/products/product2.jpg";
import product3 from "assets/products/product3.jpeg";
import product4 from "assets/products/product4.jpg";
import product5 from "assets/products/product5.png";
import product6 from "assets/products/product6.jpeg";
import product7 from "assets/products/product7.jpg";
import product8 from "assets/products/product8.png";
import product9 from "assets/products/product9.jpg";
import product10 from "assets/products/product10.png";
import product11 from "assets/products/product11.png";
import product12 from "assets/products/product12.jpg";
import product13 from "assets/products/product13.png";
import product14 from "assets/products/product14.jpg";
import product15 from "assets/products/product15.png";
import product16 from "assets/products/product16.jpg";
import product17 from "assets/products/product17.jpg";
import product18 from "assets/products/product18.jpg";
import product19 from "assets/products/product19.jpg";
import product20 from "assets/products/product20.png";

const products = [
	{
		id: 1,
		name: "Arroz Palmares 5kg",
		description: "",
		imageSrc: product1,
		categories: ["cereais"],
		prices: [
			{ local: "local1", price: 19 },
			{ local: "local2", price: 21 },
			{ local: "local3", price: 22 },
		],
	},
	{
		id: 2,
		name: "Arroz Prato Fino 5kg",
		description: "",
		imageSrc: product2,
		categories: ["cereais"],
		prices: [
			{ local: "local1", price: 17 },
			{ local: "local2", price: 14 },
			{ local: "local3", price: 18 },
		],
	},
	{
		id: 3,
		name: "Leite Condensado Moça TP 395g",
		description: "",
		imageSrc: product3,
		categories: ["laticinios", "doces"],
		prices: [
			{ local: "local1", price: 7 },
			{ local: "local2", price: 8 },
			{ local: "local3", price: 6 },
		],
	},
	{
		id: 4,
		name: "Arroz Tio João 5kg",
		description: "",
		imageSrc: product4,
		categories: ["cereais"],
		prices: [
			{ local: "local1", price: 17 },
			{ local: "local2", price: 15 },
			{ local: "local3", price: 16 },
		],
	},
	{
		id: 5,
		name: "Arroz Urbano 5kg",
		description: "",
		imageSrc: product5,
		categories: ["cereais"],
		prices: [
			{ local: "local1", price: 15 },
			{ local: "local2", price: 14 },
			{ local: "local3", price: 20 },
		],
	},
	{
		id: 6,
		name: "Panetone Bauducco",
		description: "",
		imageSrc: product6,
		categories: ["padaria"],
		prices: [
			{ local: "local1", price: 12 },
			{ local: "local2", price: 14 },
			{ local: "local3", price: 18 },
		],
	},
	{
		id: 7,
		name: "Fraldinha",
		description: "",
		imageSrc: product7,
		categories: ["açougue"],
		prices: [
			{ local: "local1", price: 42 },
			{ local: "local2", price: 44 },
			{ local: "local3", price: 41 },
		],
	},
	{
		id: 8,
		name: "Paleta",
		description: "",
		imageSrc: product8,
		categories: ["açougue"],
		prices: [
			{ local: "local1", price: 35 },
			{ local: "local2", price: 37 },
			{ local: "local3", price: 42 },
		],
	},
	{
		id: 9,
		name: "Ovos Santa Mônica",
		description: "",
		imageSrc: product9,
		categories: ["horti-fruti"],
		prices: [
			{ local: "local1", price: 15 },
			{ local: "local2", price: 14 },
			{ local: "local3", price: 16 },
		],
	},
	{
		id: 10,
		name: "Maracujá",
		description: "",
		imageSrc: product10,
		categories: ["horti-fruti"],
		prices: [
			{ local: "local1", price: 5 },
			{ local: "local2", price: 8 },
			{ local: "local3", price: 12 },
		],
	},
	{
		id: 11,
		name: "Tomate",
		description: "",
		imageSrc: product11,
		categories: ["horti-fruti"],
		prices: [
			{ local: "local1", price: 5 },
			{ local: "local2", price: 3 },
			{ local: "local3", price: 2 },
		],
	},
	{
		id: 12,
		name: "Batata Chips Ruffle's",
		description: "",
		imageSrc: product12,
		categories: ["lanches", "biscoitos", "salgadinhos"],
		prices: [
			{ local: "local1", price: 5 },
			{ local: "local2", price: 7 },
			{ local: "local3", price: 5 },
		],
	},
	{
		id: 13,
		name: "Cerveja Lata Antartica",
		description: "",
		imageSrc: product13,
		categories: ["bebidas"],
		prices: [
			{ local: "local1", price: 5 },
			{ local: "local2", price: 4 },
			{ local: "local3", price: 5 },
		],
	},
	{
		id: 14,
		name: "Leite Ninho Zero Lactose",
		description: "",
		imageSrc: product14,
		categories: ["laticinios"],
		prices: [
			{ local: "local1", price: 13 },
			{ local: "local2", price: 14 },
			{ local: "local3", price: 25 },
		],
	},
	{
		id: 15,
		name: "Iogurte Activia",
		description: "",
		imageSrc: product15,
		categories: ["laticinios"],
		prices: [
			{ local: "local1", price: 5 },
			{ local: "local2", price: 8 },
			{ local: "local3", price: 6 },
		],
	},
	{
		id: 16,
		name: "Camarão Cinza Congelado",
		description: "",
		imageSrc: product16,
		categories: ["peixaria", "açougue"],
		prices: [
			{ local: "local1", price: 55 },
			{ local: "local2", price: 48 },
			{ local: "local3", price: 69 },
		],
	},
	{
		id: 17,
		name: "Postas de Tilápia",
		description: "",
		imageSrc: product17,
		categories: ["peixaria", "açougue"],
		prices: [
			{ local: "local1", price: 15 },
			{ local: "local2", price: 18 },
			{ local: "local3", price: 20 },
		],
	},
	{
		id: 18,
		name: "Desinfetante Ypê",
		description: "",
		imageSrc: product18,
		categories: ["limpeza"],
		prices: [
			{ local: "local1", price: 5 },
			{ local: "local2", price: 4 },
			{ local: "local3", price: 3 },
		],
	},
	{
		id: 19,
		name: "Macarrão Penne Renata",
		description: "",
		imageSrc: product19,
		categories: ["massas"],
		prices: [
			{ local: "local1", price: 5 },
			{ local: "local2", price: 4 },
			{ local: "local3", price: 7 },
		],
	},
	{
		id: 20,
		name: "Farinha de Trigo",
		description: "",
		imageSrc: product20,
		categories: ["cereais"],
		prices: [
			{ local: "local1", price: 2 },
			{ local: "local2", price: 4 },
			{ local: "local3", price: 3 },
		],
	},
];

export default products;
