import { Button, Col, Form, Row } from "react-bootstrap";
import { Link } from "react-router-dom";

export default function FormRegister() {
	return (
		<Form>
			<h1 className="page-title">Cadastre-se</h1>

			<Form.Group className="form-group">
				<Form.Label>Nome</Form.Label>
				<Form.Control size="lg" placeholder="Nome" required />
			</Form.Group>

			<Row sm={2} xs={1}>
				<Col>
					<Form.Group className="form-group">
						<Form.Label>E-mail</Form.Label>
						<Form.Control size="lg" placeholder="E-mail" type="email" required />
					</Form.Group>
				</Col>
				<Col>
					<Form.Group className="form-group">
						<Form.Label>Celular</Form.Label>
						<Form.Control size="lg" placeholder="Celular" type="tel" required />
					</Form.Group>
				</Col>
			</Row>

			<Row sm={2} xs={1}>
				<Col>
					<Form.Group className="form-group">
						<Form.Label>Senha</Form.Label>
						<Form.Control size="lg" placeholder="Senha" type="password" required />
					</Form.Group>
				</Col>
				<Col>
					<Form.Group className="form-group">
						<Form.Label>Confirmação de senha</Form.Label>
						<Form.Control size="lg" placeholder="Senha" type="password" required />
					</Form.Group>
				</Col>
			</Row>

			<Row>
				<Col md={3}>
					<Form.Group className="form-group">
						<Form.Label>CEP</Form.Label>
						<Form.Control size="lg" placeholder="CEP" required />
					</Form.Group>
				</Col>
				<Col md={9}>
					<Form.Group className="form-group">
						<Form.Label>Endereço</Form.Label>
						<Form.Control size="lg" placeholder="Endereço" required />
					</Form.Group>
				</Col>
			</Row>

			<Row>
				<Col md={3}>
					<Form.Group className="form-group">
						<Form.Label>Número</Form.Label>
						<Form.Control size="lg" placeholder="Número" required />
					</Form.Group>
				</Col>
				<Col md={9}>
					<Form.Group className="form-group">
						<Form.Label>Complemento</Form.Label>
						<Form.Control size="lg" placeholder="Complemento" />
					</Form.Group>
				</Col>
			</Row>

			<Row>
				<Col md={6}>
					<Form.Group className="form-group">
						<Form.Label>Bairro</Form.Label>
						<Form.Control size="lg" placeholder="Bairro" required />
					</Form.Group>
				</Col>
				<Col md={6}>
					<Form.Group className="form-group">
						<Form.Label>Cidade</Form.Label>
						<Form.Control size="lg" placeholder="Cidade" required />
					</Form.Group>
				</Col>
			</Row>

			<Form.Group className="form-group">
				<Button size="lg" type="submit" className="w-100" id="account-button">
					Criar conta
				</Button>

				<span className="d-block mt-3 text-center">
					Já tem conta? <Link to="/auth/login">Faça login</Link>
				</span>
			</Form.Group>
		</Form>
	);
}
