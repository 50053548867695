import { useLocation, useNavigate } from "react-router-dom";
import products from "data/products";
import { useEffect, useState } from "react";
import IProduct from "types/product";
import CardProduct from "components/CardProduct";
import { Row, Col } from "react-bootstrap";
import slugify from "common/slugify";

export default function SearchPage() {
	const [queryProducts, setQueryProducts] = useState<IProduct[]>([]);
	const location = useLocation();
	const navigate = useNavigate();
	const query = location.state.query;

	useEffect(() => {
		const queryTerms = query?.toLowerCase().split("-") || [];
		const queryProducts: IProduct[] = [];

		for (let product of products) {
			for (let term of queryTerms) {
				if (!queryProducts.includes(product) && slugify(product.name).match(term)) {
					queryProducts.push(product);
				}
			}
		}

		setQueryProducts(queryProducts);
	}, [query, navigate]);

	return (
		<>
			<h1 className="page-title">Busca</h1>

			<section>
				<h2 className="page-subtitle">
					Buscando por <b>{location.state.query}</b>
				</h2>
				<Row lg={4} md={3} xs={2} className="g-4">
					{queryProducts.map((el, index) => (
						<Col key={index}>
							<CardProduct {...el} />
						</Col>
					))}
				</Row>
			</section>
		</>
	);
}
