import getPrices from "common/prices";
import useAlert from "hooks/alert";
import useCart from "hooks/cart";
import { Button, Card } from "react-bootstrap";
import { MdAddCircleOutline } from "react-icons/md";
import IProduct from "types/product";
import "./index.css";

export default function CardProduct(product: IProduct) {
	const alert = useAlert();
	const cart = useCart();

	return (
		<Card className="card-product">
			<div className="card-product-image-container">
				<Card.Img src={product.imageSrc} alt={product.name} />
			</div>
            
			<Card.Title>{product.name}</Card.Title>
			<Card.Text>{product.description}</Card.Text>
			<Card.Text className="text-secondary">{getPrices(product.prices)}</Card.Text>

			<Button
				size="sm"
				className="card-product-button"
				onClick={() => {
					cart.addProduct({ ...product });
					alert.success("Produto adicionado ao carrinho");
				}}
			>
				<MdAddCircleOutline size={20} /> Adicionar
			</Button>
		</Card>
	);
}
