import { Col, Container, Row } from "react-bootstrap";
import video from "../../assets/video-example.mp4";

export default function VideoMarketing() {
    return <Container>
        <Row>
            <Col xs={12} sm={6} md={4}>
                <h1>Smark</h1>
                <p>Smark foi desenvolvido para tornar a tarefa de fazer compras muito mais fácil e conveniente. Com ele, é possível criar listas de compras personalizadas com todos os itens necessários para abastecer a despensa e a geladeira.</p>
                <p>Mas não é só isso: Smark também permite comparar preços entre diferentes estabelecimentos para que você possa escolher a melhor opção para o seu bolso. Dessa forma, você pode economizar tempo e dinheiro na hora de ir às compras.</p>
            </Col>
            <Col xs={12} sm={6} md={8}>
                <video src={video} autoPlay loop muted width={"100%"}></video>
            </Col>
        </Row>
    </Container>
}