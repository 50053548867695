import useCart from "hooks/cart";
import { Button, Col, ListGroup, Row, Alert, ButtonGroup, FormControl } from "react-bootstrap";
import { FiPlusCircle, FiTrash } from "react-icons/fi";
import { AiOutlineCheckCircle } from "react-icons/ai";
import { BsPlusCircle } from "react-icons/bs";
import { Link } from "react-router-dom";
import useAlert from "hooks/alert";
import "./index.css";
import getPrices from "common/prices";

export default function Cart() {
	const cart = useCart();
	const alert = useAlert();

	return (
		<>
			<h1 className="page-title">Sua lista</h1>

			{!cart.products.length && <Alert>Nenhum produto adicionado à sua lista ainda</Alert>}

			{!!cart.products.length && (
				<>
					<section>
						<ListGroup>
							{cart.products.map((el) => (
								<ListGroup.Item key={el.product.id}>
									<Row>
										<Col md={1} xs={6} sm={6}>
											<img src={el.product.imageSrc} alt={el.product.name} className="img-fluid" />
										</Col>
										<Col md={10} xs={6} sm={6} className="py-3 d-flex flex-column justify-content-between">
											<h5>{el.product.name}</h5>
											{getPrices(el.product.prices)}
											{el.product.description && <p>el.product.description</p>}

											<div className="d-flex justify-content-between mt-3">
												<ButtonGroup>
													<Button
														variant="danger"
														className="ms-auto"
														onClick={() => {
															cart.removeProduct(el.product.id);
															alert.success("Produto removido do carrinho");
														}}
													>
														<FiTrash />
													</Button>
													<FormControl
														className="product-cart-quantity"
														type="number"
														min={1}
														max={99}
														value={cart.getQuantityOfProductsById(el.product.id)}
														onChange={(e) => cart.setProductQuantity(el.product.id, Number(e.target.value))}
													/>
													<Button variant="outline-dark" onClick={() => cart.addProduct(el.product)}>
														<FiPlusCircle />
													</Button>
												</ButtonGroup>
											</div>
										</Col>
									</Row>
								</ListGroup.Item>
							))}
						</ListGroup>

						<div className="d-md-flex flex-wrap justify-content-between mt-5">
							<Link to="/" className="btn btn-lg btn-light d-flex g-1 align-items-center justify-content-center mb-3">
								<BsPlusCircle size={24} color="#484848" />
								Adicionar mais itens
							</Link>

							<Link to="/checkout" className="d-flex justify-content-center">
								<Button variant="success" size="lg" className="d-flex g-1 align-items-center justify-content-center mb-3 ">
									<AiOutlineCheckCircle size={24} color="#f6f6f6" />
									Finalizar pedido
								</Button>
							</Link>
						</div>
					</section>
				</>
			)}
		</>
	);
}
