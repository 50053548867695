import FormLogin from "./Form";

export default function Login() {
	return (
		<>
			

			<FormLogin />
		</>
	);
}
